export default {
  currency: 'SEK',
  currency_monthly: 'Månad',
  popup_close: 'Stäng',
  navigate_back: 'Tillbaka',
  vehicle_list_no_matches: 'Tyvärr har vi inga motorcyklar som motsvarar din specifikation.',
  vehicle_list_other_vehicles: 'Övriga motorcyklar i lager',
  vehicle_list_sort_by: 'Sortera efter',
  vehicle_list_sort_by_price: 'Pris',
  filter_models: 'Modeller',
  filter_color: 'Färg',
  filter_availability: 'Tillgänglighet',
  filter_power: 'Effekt',
  filter_torque: 'Vridmoment',
  filter_distance: 'Avstånd',
  filter_location: 'Återförsäljare',
  filter_location_title: 'Hitta återförsäljare',
  filter_location_search_radius: 'Sök inom ett specifikt område eller välj en återförsäljare nedan',
  filter_location_search_distance: 'Avstånd',
  filter_search_for_equipment: 'Sök utrustning',
  filter_reset: 'Återställ filter',
  filter_price: 'Pris',
  filter_applied_models: 'Modeller',
  filter_applied_locations: 'Platser',
  vehicle_details_engine_output: 'Effekt',
  vehicle_details_cylinder_capacity: 'Cylindervolym',
  vehicle_details_vehicle_identifier: 'ID',
  vehicle_details_360_View: '360 Översikt',
  vehicle_details_overview: 'Översikt',
  vehicle_details_gallery: 'Bildgalleri',
  vehicle_details_video: 'Video',
  vehicle_details_equipment: 'Utrustning',
  vehicle_details_accessories: 'Tillbehör',
  vehicle_details_total_price: 'Totalpris',
  vehicle_details_financing_from: 'Finansiering från',
  vehicle_details_open_calculator: 'Lånekalkyl',
  vehicle_details_request_offer: 'Kontakta mig',
  financial_calc_downpayment: 'Kontantinsats',
  financial_calc_period: 'Avtalstid',
  financial_calc_remaining: 'Restvärde',
  financial_calc_monthly_payment: 'Uppskattad månadsavgift',
  financial_calc_text:
    'Friskrivning: Detta är det aktuella priset för just denna motorcykel. Priset bestäms av återförsäljaren med motorcykel i lager och kan komma att ändras. ',
  financial_calc_text1:
    'Ränta :interest-rate% (effektiv ränta :effective-interest-rate%) enligt ränteläge 2025. För privatperson är minsta kontantinsats :min-downpayment%. Ovanstående beräkning är endast ett exempel som är baserat på en rörlig indikativ ränta. Lånet är ett annuitetslån, vilket ger en jämn kostnad under hela kontraktstiden. Uppläggnings- och administrationsavgift tillkommer med f.n. 595 kr resp. 55 kr. Vi gör ingen kreditförfrågan baserat på denna kalkyl. Kreditgivare BMW Financial Services Scandinavia AB.',
  book_first_name: 'Namn',
  book_last_name: 'Efternamn',
  book_phone: 'Telefon',
  book_email: 'E-post',
  book_enter_required_fields: 'Alla fält är obligatoriska',
  book_marketing_title: 'Samtycke till mottagande av marknadsföring',
  book_marketing_checkbox1_text:
    'Jag vill ta emot marknadsföring från BMW via e-post, post, telefon/meddelandetjänster och i bilen (om sådana tjänster stöds).',
  book_marketing_checkbox1_details:
    'Jag samtycker till att BMW Northern Europe AB (org.nr. 556313-5002, postadress: Box 794, 191 27, Sollentuna) behandlar personuppgifter som jag lämnat (t.ex. kontaktuppgifter och annan personlig information, såsom födelsedatum och typ av körkort samt hobbys och intressen) för kundvårdsändamål och för riktad marknadsföring av produkter och tjänster från BMW-koncernen samt för marknadsundersökningar. BMW Northern Europe AB får också, för ovan nämnda ändamål, överföra dessa uppgifter till vissa närstående bolag liksom till vissa avtalsparter och verkstäder (den återförsäljare jag uppgivit att jag föredrar, som jag köpt ett fordon av eller varit på service- eller rådgivningsbesök hos eller den närmast belägna återförsäljare som kan hjälpa mig). Uppdateringar av sådan information får delas mellan de närstående bolagen.',
  book_marketing_checkbox2_text:
    'Jag vill även få erbjudanden från BMW Group om produkter och tjänster särskilt utvalda på grundval av mina personliga preferenser och på min användning av produkter och tjänster.',
  book_marketing_checkbox2_details:
    'Jag samtycker till att mina personuppgifter behandlas för att skapa en individuell kundprofil genom statistisk analys. Tack vare denna kundprofil kommer jag få marknadsföring med innehåll anpassat för mig, såsom t.ex. personliga erbjudanden från BMW Northern Europe AB och vissa närstående bolag samt vissa avtalsparter och verkstäder (den återförsäljare jag uppgivit att jag föredrar, som jag köpt ett fordon av eller varit på service- eller rådgivningsbesök hos eller den närmast belägna återförsäljare som kan hjälpa mig). Jag samtycker också till att kundprofilen överförs från BMW Northern Europe AB till vissa närstående bolag samt till mina avtalsparter och verkstäder för de ovan nämnda ändamålen. I förekommande fall kan följande uppgifter som jag tillhandahåller eller som skapas genom min användning av BMW Northern Europe AB:s, vissa närstående bolags och deras avtalsparters samt verkstäders produkter eller tjänster komma att behandlas för att skapa kundprofilen: kontaktuppgifter (t.ex. namn, adress, e-postadress), tilläggsinformation/preferenser (t.ex. föredragen återförsäljare, hobbys), identifikationsuppgifter (t.ex. kundnummer, avtalsnummer), kundhistorik (t.ex. mottagna erbjudanden, uppgifter om fordonsköp, uppgifter om återförsäljare), fordonsdata (t.ex. användardata i appen ”My BMW”: mätarställning, sträcka till tom tank), data från användning av appar, hemsidor eller sociala medier. En uttömmande lista med uppgifter från dessa kategorier hittar du här.',
  book_policy: 'Ändra eller återkalla tidigare lämnat samtycke.',
  book_policy_details:
    'Jag kan när som helst återkalla de samtycken jag lämnat till BMW Northern Europe AB genom att kontakta BMW:s kundtjänst 0200-22 82 00 eller nordic.dataprivacy@bmw.se. Vid fullständigt återkallande kommer min kund-profil inte längre att behandlas och jag kommer inte längre att få marknadsföringsutskick. Via de ovan angivna kommunikationskanalerna kan jag också begära information om mina uppgifter som lagras av BMW Northern Europe AB och även begära rättning, radering eller begränsning av behandling av mina personuppgifter. Ytterligare information angående BMW Northern Europe AB:s behandling av mina personuppgifter och mina rättigheter finns att tillgå online i BMW:s privacy policy.',
  book_submit: 'Kontakta mig',
  confirm_title: 'Tack för din förfrågan',
  confirm_subtitle: 'Du kommer inkort bli kontaktad av återförsäljare.',
  available_vehicles: 'Tillgängliga fordon',
  location: 'PLATS',
  availability_IN_STOCK: 'I lager',
  availability_ON_THE_WAY: 'On the way',
  view_more: 'Visa mer',
  book_a_test_ride: 'Boka provkörning',
  get_an_offer: 'Få ett prisförslag',
  technical_data: 'Technical data',
  technical_data_Power: 'Effekt',
  technical_data_Cylinder: 'Cylindrar',
  'technical_data_Top speed': 'Toppfart',
  technical_data_Torque: 'Vridmoment',
  'technical_data_Drive Type': 'Drivtyp',
  technical_data_Transmission: 'Växellåda',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Tankvolym',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Välj finansieringsalternativ',
  vehicle_details_add_contact_info: 'Lägg till kontaktinformation',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Justera parametrar',
  book_field_required: 'Obligatoriskt',
  request_test_ride: 'Skicka förfrågan',
  color_GRAY: 'Grå',
  color_WHITE: 'Vit',
  color_BLACK: 'Svart',
  color_RED: 'Röd',
  color_BLUE: 'Blå',
  'promotion_Test ride your dream BMW': 'Provkör din dröm BMW',
  'promotion_Build your ultimate BMW bike': 'Bygg din ultimata BMW',
  'promotion_Get the best deal': 'Få det bästa erbjudandet',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Boka provkörning',
  promotion_action_Configure: 'Konfigurera',
  'promotion_action_Request for offer': 'Begär offert',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Friskrivning: Friskrivning: Detta är det aktuella priset för just denna motorcykel. Priset bestäms av återförsäljaren med motorcyckeln i lager och kan komma att ändras.',
  show_filters: 'Visa filter',
  offer_success_message:
    'Förfrågan har skickats framgångsrikt. Vårt team kommer att kontakta dig så snart som möjligt.',
  'package_Other equipment': 'Annan utrusting',
  no_results_found: 'Inga resultat hittades',
  book_message: 'Meddelande',
  map: 'Map',
  list: 'List',
  search_within_specific_area: 'Sök inom ett specifikt område eller välj en återförsäljare nedan',
  enter_location: 'Ange en plats',
  'Your filter selection did not return any matches.': 'Inga resultat.',
  'Please modify your search criteria or': 'Ändra sökningen eller',
  'reset filters': 'återställ filtren',
  'to start again': ' för att börja om.',
  card_special_price: 'Kampanjpris',
  card_price: 'Totalpris',
};
